import { VStack, Link } from "@chakra-ui/react";
// import { useState } from "react";

const Navbar = () => {
  // const [_, setCurrentSection] = useState("Home");
  return (
    <>
      <VStack pos="fixed" top={"50%"} right={0} pr={3} justify={"center"}>
        <Link
          href="#home"
          // onClick={() => setCurrentSection("Home")}
          _hover={{ cursor: "pointer", fontSize: 20, color: "gray.800" }}
          fontSize={15}
          color='gray'
        >
          Home
        </Link>
       
        <Link
          href="#about"
          // onClick={() => setCurrentSection("About")}
          _hover={{ cursor: "pointer", fontSize: 20, color: "gray.800" }}
          fontSize={15}
          color='gray'
        >
          About
        </Link>
        <Link
          href="#gallery"
          // onClick={() => setCurrentSection("Gallery")}
          _hover={{ cursor: "pointer", fontSize: 20, color: "gray.800" }}
          fontSize={15}
          color='gray'
        >
          Gallery
        </Link>
      </VStack>
    </>
  );
};

export default Navbar;
