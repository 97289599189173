import { Flex, Heading, Text } from "@chakra-ui/react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const yingyang = [
  { src: require("../assets/images/yingyang/img01.png") },
  { src: require("../assets/images/yingyang/img02.png") },
  { src: require("../assets/images/yingyang/img03.png") },
  { src: require("../assets/images/yingyang/img04.png") },
  { src: require("../assets/images/yingyang/img05.png") },
  { src: require("../assets/images/yingyang/img06.png") },
  { src: require("../assets/images/yingyang/img07.png") },
  { src: require("../assets/images/yingyang/img08.png") },
];

const insecurities = [
  { src: require("../assets/images/insecurities/img1.jpg") },
  { src: require("../assets/images/insecurities/img2.jpg") },
  { src: require("../assets/images/insecurities/img3.jpg") },
  { src: require("../assets/images/insecurities/img4.jpg") },
  { src: require("../assets/images/insecurities/img5.jpg") },
  { src: require("../assets/images/insecurities/img6.jpg") },
  { src: require("../assets/images/insecurities/img7.jpg") },
  { src: require("../assets/images/insecurities/img8.jpg") },
  { src: require("../assets/images/insecurities/img9.jpg") },
  { src: require("../assets/images/insecurities/img10.jpg") },
  { src: require("../assets/images/insecurities/img11.jpg") },
  { src: require("../assets/images/insecurities/img12.jpg") },
]

const marilyn = [
  { src: require('../assets/images/marilyn/img1.jpg')},
  { src: require('../assets/images/marilyn/img2.jpg')},
  { src: require('../assets/images/marilyn/img3.jpg')},
]

const misc = [
  { src: require('../assets/images/random/img1.jpg')},
]
const Gallery = () => {
  return (
    <>
      <Flex
        pt={10}
        fontFamily={"times"}
        id="gallery"
        // mt={{ base: 500, md: 100 }}
        w="100%"
        h="100vh"
        flexDir={"column"}
      >
        <Heading
          textAlign={"center"}
          fontFamily={"times"}
          fontSize={{ base: 60, md: 80 }}
        >
          Gallery
        </Heading>
        <Text mt={10} color="gray" fontSize={30} alignSelf={"center"}>
          Ying Yang
        </Text>
        <Carousel images={yingyang} style={{ height: "100%", width: "100%" }} />

        <Text mt={10} color="gray" fontSize={30} alignSelf={"center"}>
        Insecurities of Women
        </Text>
        <Carousel images={insecurities} style={{ height: "100%", width: "100%" }} />

        <Text mt={10} color="gray" fontSize={30} alignSelf={"center"}>
          If Marilyn Monroe were on social media
        </Text>
        <Carousel images={marilyn} style={{ height: "100%", width: "100%" }} />
        <Text mt={10} color="gray" fontSize={30} alignSelf={"center"}>
          Misc
        </Text>
        <Carousel images={misc} style={{ height: "100%", width: "100%" }} />
      </Flex>
    </>
  );
};

export default Gallery;
