import { Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as Dots } from "../assets/images/dots.svg";
import TypeWriterEffect from "react-typewriter-effect";
import BannerImage from "../assets/images/banner.png";
import { Bounce, Zoom } from "react-reveal";

const Home = () => {
  const myRef = document.querySelector(".scrollable-div");

  return (
    <>
      <Flex
        pt={20}
        w="100%"
        h="100vh"
        flexDir={{ base: "column", md: "row" }}
        justify="space-between"
        id="home"
      >
        <VStack>
          <Bounce>
            <Flex>
              <Flex zIndex={0} w={300} h={300} bg="cyan.600" rounded="full" />
              <Flex ml={85} mt={75} flexDir={"column"} pos="absolute">
                <Heading
                  fontFamily={"times"}
                  color="white"
                  fontSize={{ base: 50, md: 70 }}
                >
                  Rena
                </Heading>
                <Heading
                  fontFamily={"times"}
                  ml={95}
                  fontSize={{ base: 60, md: 80 }}
                >
                  Riojas
                </Heading>
              </Flex>
            </Flex>
          </Bounce>
          <Flex>
            <Dots
              fill="#f2f2f2"
              style={{ marginTop: 20, zIndex: -1 }}
              width={300}
              height={350}
            />
            <Flex
              ml={{ base: 5, md: 10 }}
              pos="absolute"
              w={{ base: 300, md: 350 }}
              flexDir={"column"}
            >
              <TypeWriterEffect
                textStyle={{
                  fontSize: 20,
                  width: "100%",
                  marginTop: 120,
                }}
                startDelay={100}
                typeSpeed={50}
                cursorColor="black"
                text={
                  '"An arrow can only be shot by pulling it backward. When life is dragging you back with difficulties, it means it\'s going to launch you into something great. So just focus, and keep aiming."'
                }
                scrollArea={myRef}
              />
              <Text fontSize={20} alignSelf="end">
                - Paulo Coelho
              </Text>
            </Flex>
          </Flex>
        </VStack>
        <Zoom bottom>
          <Flex>
            <Image
              w={{ base: "100%", md: 500 }}
              h={{ base: 400, md: "100%" }}
              zIndex={1}
              src={BannerImage}
            />
            <Flex
              right={-8}
              top={-10}
              pos="absolute"
              bg={"cyan.600"}
              w={70}
              h={70}
              rounded="full"
            />
          </Flex>
        </Zoom>
      </Flex>
    </>
  );
};
export default Home;
