import { Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import portfolioImage from "../assets/images/me.jpg";
const About = () => {
  return (
    <>
      <Flex pt={10} mt={200} id='about' w="100%" h="100vh" flexDir={"column"}>
        <Heading textAlign={{base: 'center', md: 'start'}} fontFamily={"times"} fontSize={{base: 60, md: 80}}>
          About Me
        </Heading>
        <Stack direction={{ base: "column", md: "row" }}>
          <Image alignSelf={{base: 'center', md: 'start'}} src={portfolioImage} w={200} h={200} />
          <Flex flexDir={'column'}>
            <Text>
              Art and design takes me into my own little world, able to create
              however I want. I find myself getting lost, in the best way,
              escaping the crowded thoughts in my head, even for just mere
              moments. The responsibilities and stressors that inundate my life
              fall to the wayside. It may not be evident in all of my work, but
              my work is my source of oxygen. The restrictions and limitations
              placed on every facet of my life need not apply when I'm creating.
            </Text>
            <Text mt={2}>
              I can't remember the first time where I knew art was my calling.
              Maybe it was in elementary school where we had small art projects.
              I always felt joy doing them, I never wanted to stop creating art!
              Math, history, and science could wait, I was having fun. When I
              got older, I knew I wanted to do something with art, I just didn't
              know what type of art. That was when I got to high school and I
              explored the world of yearbook. At first I was skeptical because I
              didn't think I would have the eye for designing layouts, boy I was
              wrong. It turned out to be so much fun! I had the freedom to
              design anything I want on a spread, I had the freedom to arrange
              things the way I thought was best.
            </Text>
            <Text mt={2}>
              I want to grow in my artistic journey! I want to believe in myself
              that I am talented and that I do have a future in this field. I
              want to grow and master my understanding of art and design.

              
            </Text>
          </Flex>
        </Stack>
      </Flex>
    </>
  );
};

export default About;
