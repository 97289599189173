import { Flex } from "@chakra-ui/react";
import Navbar from "../components/NavBar";
import About from "./About";
import Gallery from "./Gallery";
import Home from "./Home";

const App = () => {
  return (
    <>
      <Flex
        pl={90}
        pr={90}
        flexDir={"column"}
        fontFamily={"times"}
        w="100%"
        h="auto"
        minH="100vh"
      >
        <Navbar />
        <Home />
        <About />

        <Gallery />

      </Flex>
    </>
  );
};

export default App;
